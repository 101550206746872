import React, {useEffect, useState} from "react";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import ApiService from "../../Service/ApiService";
import gs from "../../Theme/common.module.scss";
import cm from "../../Theme/common.module.scss";
import {useHistory} from "react-router-dom";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";

interface Props {
    hash: string,
    results?: any,

    onChangeHash?(hash: string): void
}

function Request(props: Props) {
    useEffect(() => {
        if (typeof props.onChangeHash === "function") {
            props.onChangeHash(props.hash);
        }
    }, [props])

    const [name, setName] = useState(props.results?.Name ?? '');
    const [nameValid, setNameValid] = useState<boolean>(true);
    useEffect(() => {
        setNameValid(!!name.match(/^\w+ \w+(?: \w+)*$/));
    }, [name])

    const [phone, setPhone] = useState(props.results?.Phone ?? '');
    const [telValid, setTelValid] = useState<boolean>(true);
    useEffect(() => {
        setTelValid(!!phone.match(/\+?[0-9 ]{7,40}/));
    }, [phone])
    const [message, setMessage] = useState('');

    const [error, setError] = useState<boolean>(false)
    useEffect(() => {
        setError(false);
    }, [name, nameValid, phone, telValid])

    const [sent, setSent] = useState<boolean>(false);

    const history = useHistory();

    const onSubmit = () => {
        if (name.length && nameValid && phone.length && telValid) {
            setError(false);
            setSent(true);
            ApiService.request({
                Hash: props.hash,
                Name: name,
                Phone: phone,
                Message: message
            }).then(() => {
                setSent(true);
            })
        } else {
            setError(true);
        }

    }

    return <ScrollTop>
        <h2>Telefonische Beratung sichern</h2>
        <h3 className={c(bs['mb-5'])}>Sichern Sie sich Ihre persönliche Beratung</h3>
        {!sent ? <>
                <div className={c(bs['mb-5'], bs['row'])}>
                    <label htmlFor="name" className={c(bs['col-12'], bs['col-form-label'])}>
                        Vor- und Nachname
                    </label>
                    <div className={c(bs['col-12'])}>
                        <input type="text"
                               className={c(bs['form-control'], gs['customFormControl'], name.length ? (nameValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                               id="name"
                               placeholder="Michaela Mustermann"
                               autoComplete="name"
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className={c(bs['mb-5'], bs['row'])}>
                    <label htmlFor="phone" className={c(bs['col-12'], bs['col-form-label'])}>
                        Telefon
                    </label>
                    <div className={c(bs['col-12'])}>
                        <input type="text"
                               className={c(bs['form-control'], gs['customFormControl'], phone.length ? (telValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                               id="phone" placeholder="+43 7232 123456"
                               autoComplete="tel"
                               value={phone}
                               onChange={(e) => setPhone(e.target.value.match(/[+ 0-9]*/)?.join('') ?? '')}
                        />
                    </div>
                </div>
                <div className={c(bs['mb-5'], bs['row'])}>
                    <label htmlFor="message" className={c(bs['col-12'], bs['col-form-label'])}>
                        Nachricht
                    </label>
                    <div className={c(bs['col-12'])}>
                <textarea
                    className={c(bs['form-control'], gs['customFormControl'])}
                    id="message"
                    placeholder="Ich möchte..."
                    autoComplete="off"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                    </div>
                </div>
                <div className={c(bs['mb-5'])}>
                    <p className={c(bs['text-danger'], error ? null : bs['d-none'])}>
                        Bitte füllen Sie alle erforderlichen Felder aus!
                    </p>
                    <button onClick={() => onSubmit()} className={gs.bigRedButton}>Jetzt anfragen</button>
                </div>
            </>
            :
            <div>
                <p>Vielen Dank für die Anfrage! Wir werden uns in Kürze bei Ihnen melden.</p>
                <div className={c(bs['row'], bs['my-5'])}>
                    <div className={c(bs['col-sm-6'])}>
                        <button onClick={() => history.push('/result/' + props.hash)}
                                className={c(gs.bigRedButton, bs['mb-3'])}>
                            Zurück zum Ergebnis
                        </button>
                    </div>
                    <div className={c(bs['col-sm-6'])}>
                        <div className={c(bs['text-sm-end'])}>
                            <button className={c(bs['text-uppercase'], cm['restartBtn'])}
                                    onClick={() => history.push("/")}>
                                Neu starten
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </ScrollTop>
}

export default Request;
