import React, {useState} from "react";
import c from 'classnames';
import s from './Info.module.scss';

const Info = (props: { text: string }) => {
    const [show, setShow] = useState(false);
    const infoPng = process.env.REACT_APP_HOST_BASE_URL + "public/resources/themes/quickfix-clean/img/neoflow/info.png";

    return (
        <div className={s.tooltipContainer}>
            <div className={c(s.tooltipBox, show ? s.visible : null)}>
                {props.text}
                <span className={s.tooltipArrow}/>
            </div>
            <div
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                onClick={() => setShow(!show)}
            >
                <img src={infoPng} alt="Info" className={s.info}/>
            </div>
        </div>
    );
};
export default Info;
