import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import cm from "../../Theme/common.module.scss";
import r from "./Result.module.scss";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import PropertyTable from "../../Components/PropertyTable/PropertyTable";
import RequestForm from "../../Components/RequestForm/RequestForm";

interface Props {
    hash: string,
    results?: any,

    onChangeHash?(hash: string): void
}

function Result(props: Props) {
    useEffect(() => {
        if (typeof props.onChangeHash === "function") {
            props.onChangeHash(props.hash);
        }
    }, [props])
    const history = useHistory();

    const circlePng = process.env.REACT_APP_HOST_BASE_URL + "public/resources/themes/quickfix-clean/img/neoflow/circle.png";
    const checkPng = process.env.REACT_APP_HOST_BASE_URL + "public/resources/themes/quickfix-clean/img/neoflow/check.png";

    const onRequestCall = () => {
        document.getElementById('requestForm')?.scrollIntoView({
            behavior: 'smooth',
            block: "start",
            inline: "nearest"
        });
    }

    const [extraCost, showExtraCost] = useState<boolean>(false);

    return <ScrollTop>
        <h2>Ihr NeoFlow Ergebnis</h2>
        <h3 className={c(bs['mb-5'])}>Diese Wohnraumlüftung passt zu Ihnen</h3>
        {typeof props.results === "object" && props.results !== null && Array.isArray(props.results.Results) && props.results.Results.length ?
            props.results.Results.map((item: any, index: number) =>
                <div key={item.ID}>
                    <div className={c(bs['row'], bs['mb-5'])}>
                        <div className={c(bs['col-md-6'], bs['mb-5'], bs['mb-lg-0'])}>
                            <div className={c(bs['p-5'], r['bg-result'])}>
                                <h2 className={c(bs['mt-0'], r.textRed)}>{item.Title}</h2>
                                <p>
                                    in Comfort Variante inkl. Montage<br/>
                                    <strong>{item.PriceComfort}</strong>
                                </p>
                                {item.Image ?
                                    <div className={c(bs['text-center'])}>
                                        <img className={c(bs['img-fluid'], bs['my-5'], r['productImg'])}
                                             src={item.Image}/>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <button onClick={() => onRequestCall()}
                                    className={c(cm.bigRedButton, bs['mt-0'], bs['w-100'], bs['mb-3'])}>
                                Jetzt Beratung sichern
                            </button>
                        </div>
                        <div className={c(bs['col-md-6'], bs['mb-5'], bs['mb-lg-0'], bs['p-5'])}>
                            {extraCost ?
                                <>
                                    <h3 className={c(bs['mt-0'])}>Fremdkosten, nicht im Angebot inkludiert:</h3>
                                    <ul className={c(r.hovalUl)}>
                                        {item.NotInProductIncluded.map((text: string, index: number) => <li
                                            key={index}>{text}</li>)}
                                    </ul>
                                    <p className={c(bs['mt-5'])}>
                                        <a role="button" onClick={() => showExtraCost(!extraCost)}>Zurück</a>
                                    </p>
                                </>
                                :
                                <>
                                    <h3 className={c(bs['mt-0'])}>In allen Varianten inkludiert:</h3>
                                    <ul className={c(r.hovalUl)}>
                                        {item.InProductIncluded.map((text: string, index: number) =>
                                            <li key={index}>{text}</li>
                                        )}
                                    </ul>
                                    {item.NotInProductIncluded.length ?
                                        <p className={c(bs['mt-5'])}>
                                            Nicht im Angebot inkludiert sind nur <a role="button"
                                                                                    onClick={() => showExtraCost(!extraCost)}>Fremdkosten</a>.
                                        </p>
                                        : null}
                                </>
                            }
                        </div>
                    </div>

                    <PropertyTable
                        properties={item.Properties}
                        pricedProperties={item.PricedProperties}
                        PriceComfort={item.PriceComfort}
                        PricePremium={item.PricePremium}
                        PriceKeller={item.PriceKeller}
                        PriceMassivbau={item.PriceMassivbau}
                    />

                    <small>
                        <img src={checkPng} className={r.checkCircle} alt="Inklusive"/> = Inklusive<br/>
                        <img src={circlePng} className={r.checkCircle} alt="Optional"/> = Optional<br/>
                        <br/>
                        {item.ExtraInfo && item.ExtraInfo.split("\n").map((item: string) => <>{item}<br/></>)}
                    </small>
                    {index + 1 === props.results.Results.length ? null : <hr className={r.separator}/>}
                </div>
            )
            :
            <>
                <p>Wenn in Ihren Angaben ein oder mehrere der folgenden Fälle eintrifft, haben wir für Sie leider keine
                    schlüsselfertige Lösung parat:</p>
                <ul>
                    <li>Die gesamte bewohnbare Fläche ist {'>'} 200 m²</li>
                    <li>Die gesamte bewohnbare Fläche ist {'>'} 180 m² und im Haushalt leben maximal 3 Personen</li>
                    <li>Die Anzahl der im Haushalt lebenden Personen ist {'>'} 5</li>
                    <li> Die Bauweise ist weder Trockenbau noch Massivbau</li>
                    <li> Die Anzahl der Zuluft-Räume (Ess- und Wohnzimmer, Arbeitsraum, Wirtschaftsraum, Schlafzimmer,
                        Kinderzimmer, Gästezimmer) ist größer als 10
                    </li>
                </ul>
                <p>Wir bereiten aber gerne eine maßgeschneiderte Lösung für Sie auf.</p>
            </>
        }
        <div className={c(bs['row'], r.padMe)}>
            <div className={c(bs['col-12'], bs['text-center'])}>
                <button className={c(bs['text-uppercase'], cm['restartBtn'])}
                        onClick={() => history.push("/")}>
                    Neu starten
                </button>
            </div>
        </div>
        <RequestForm hash={props.hash} results={props.results}/>
    </ScrollTop>
}

export default Result;

