import React, {ChangeEvent, useEffect, useState} from "react";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import s from './Form.module.scss';
import AmountCard from "../../Components/AmountCard/AmountCard";
import {Config, RoomOption, Submission} from "../Main/Main";
import CitySearch from "../../Components/CitySearch/CitySearch";
import {useHistory} from "react-router-dom";
import gs from "../../Theme/common.module.scss";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import Info from "../../Components/Info/Info";

interface Props {
    config: Config,

    onSubmit?(partialSubmission: Submission): void
}

function Form(props: Props) {
    const [placeId, setPlaceId] = useState(0);

    const [area, setArea] = useState<number>();

    const [areaValid, setAreaValid] = useState<boolean>(true);
    useEffect(() => {
        setAreaValid(typeof area !== "undefined" && area > 0);
    }, [area])

    const [personOptionId, setPersonOptionId] = useState(0);
    const onChangePerson = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setPersonOptionId(parseInt(e.target.value));
        }
    }

    const [constructionOptionId, setConstructionOptionId] = useState(0);
    const onChangeConstruction = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setConstructionOptionId(parseInt(e.target.value));
        }
    }

    const [roomOptions, setRoomOptions] = useState<RoomOption[]>([]);
    const onChangeRoom = (roomId: number, amount: number) => {
        if (typeof roomOptions === "undefined") {
            return;
        }
        let newRoomOptions = roomOptions.filter(item => item.ID !== roomId);
        if (amount > 0) {
            newRoomOptions.push({ID: roomId, Amount: amount});
        }
        setRoomOptions(newRoomOptions);
    }

    const history = useHistory();

    const onSubmit = () => {
        if (placeId && areaValid && personOptionId && constructionOptionId && roomOptions.length) {
            if (typeof props.onSubmit === 'function') {
                let partialSubmission = {
                    ZipID: placeId,
                    ConstructionOptionID: constructionOptionId,
                    PersonOptionID: personOptionId,
                    RoomOptions: roomOptions,
                    Area: area
                };

                props.onSubmit(partialSubmission);
            }
            setError(false);
            history.push('/submit')
        } else {
            setError(true);
        }
    }

    const [error, setError] = useState<boolean>(false)
    useEffect(() => {
        setError(false);
    }, [placeId, areaValid, personOptionId, constructionOptionId, roomOptions])


    return (
        <ScrollTop>
            <p className={c(bs['mb-5'])}>Erhalten Sie nach wenigen Schritten sofort die passenden Lüftungen inklusive
                Fixpreis-Angebot für Ihren Neubau!</p>
            <h2>Gebäude</h2>
            <h3 className={c(bs['mb-5'])}>Allgemeine Infos über Ihr Bauvorhaben</h3>
            <div className={c(bs['mb-5'], bs['row'])}>
                <label htmlFor="place" className={c(bs['col-12'], bs['col-form-label'], bs['pb-3'])}>
                    Ort des Projekts
                </label>
                <CitySearch onChange={(id) => setPlaceId(id)}/>
            </div>
            <div className={c(bs['mb-5'], bs['row'])}>
                <label htmlFor="area" className={c(bs['col-md-12'], bs['col-form-label'], bs['pb-3'])}>
                    Gesamte Nettofläche (bewohnbar) <Info
                    text="Bitte nur die gesamte bewohnbare und beheizte Fläche (ohne Mauerwerk) angeben."/>
                </label>
                <div className={c(bs['col-md-12'])}>
                    <div className={c(bs['input-group'])}>
                        <input type="number"
                               className={c(gs['customFormControl'], bs['form-control'], area !== undefined ? (areaValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                               id="area" placeholder="100"
                               onChange={(e) => setArea(parseInt(e.target.value))}
                        />
                        <span className={c(bs['input-group-text'], s['br0'], s.append)}>m²</span>
                    </div>
                </div>
            </div>
            <div className={c(bs['mb-5'], bs['row'])}>
                <label htmlFor="place" className={c(bs['col-12'], bs['col-form-label'], bs['pb-3'])}>
                    Anzahl der im Haushalt lebenden Personen
                </label>
                <div className={c(bs['col-12'])}>
                    {props.config.NeoFlowPersonOptions?.map((item) =>
                        <>
                            <input type="radio"
                                   className={c(bs['btn-check'])}
                                   name="person"
                                   value={item.ID}
                                   id={`person-${item.ID}`}
                                   autoComplete="off"
                                   onChange={onChangePerson}/>
                            <label className={c(bs['btn'], s.borderGray, s.buttonLabel)}
                                   htmlFor={`person-${item.ID}`}>{item.Title}</label>
                        </>
                    )}
                </div>
            </div>
            <div className={c(bs['mb-5'], bs['row'])}>
                <label htmlFor="place" className={c(bs['col-12'], bs['col-form-label'], bs['pb-3'])}>
                    Bauweise
                </label>
                <div className={c(bs['col-12'])}>
                    {props.config.NeoFlowConstructionOptions?.map((item) =>
                        <>
                            <input type="radio"
                                   className={c(bs['btn-check'])}
                                   name="construction"
                                   value={item.ID}
                                   id={`construction-${item.ID}`}
                                   autoComplete="off"
                                   onChange={onChangeConstruction}
                            />
                            <label className={c(bs['btn'], s.borderGray, s.buttonLabel)}
                                   htmlFor={`construction-${item.ID}`}>{item.Title}</label>
                        </>
                    )}
                </div>
            </div>
            <div className={c(bs['mb-5'], bs['row'])}>
                <label htmlFor="place" className={c(bs['col-12'], bs['col-form-label'], bs['pb-3'])}>
                    Raumaufteilung
                </label>
                <div className={c(bs['col-12'])}>
                    <div className={c(bs.row, bs['justify-content-stretch'])}>
                        {props.config.NeoFlowRooms?.map((room) =>
                            <AmountCard title={room.Title} id={room.ID} key={room.ID} imageSrc={room.Image}
                                        onChange={onChangeRoom}/>
                        )}
                    </div>
                </div>
            </div>
            <div className={c(bs['mb-5'])}>
                <p className={c(bs['text-danger'], error ? null : bs['d-none'])}>
                    Bitte füllen Sie alle erforderlichen Felder aus!
                </p>
                <button onClick={() => onSubmit()} className={gs.bigRedButton}>Angebot erstellen</button>
            </div>
        </ScrollTop>
    );
}

export default Form;

