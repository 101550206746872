import React from "react";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import PropertyTableRow from "./PropertyTableRow";
import s from "./PropertyTable.module.scss";

interface Props {
    properties: any,
    pricedProperties: any,
    PriceComfort: string,
    PricePremium: string,
    PriceKeller: string,
    PriceMassivbau: string,
}

function PropertyTable(props: Props) {

    return <table className={c(bs['table'], bs['mb-5'], s.results, s.table)}>
        <thead className={c(s.thead)}>
        <tr className={c(s.bgRed, s.tr)}>
            <th scope="col" className={c(s.th)}/>
            <th scope="col" className={c(bs['text-center'], s.th, s.min)}>Comfort</th>
            <th scope="col" className={c(bs['text-center'], s.th, s.min)}>Premium</th>
        </tr>
        </thead>
        <tbody className={c(s.tbody)}>
        {props.properties && props.properties.map((property: any, index: number) =>
            <PropertyTableRow
                property={property}
                even={!!(index % 2)}
                key={index}
            />
        )}
        <tr className={c(s.bgRed, s.tr, s.priceTr)}>
            <td className={c(s.td, s.priceTd)}><b>Preis</b></td>
            <td className={c(bs['text-center'], s.td, s.min, s.comfort)}><b>{props.PriceComfort}</b></td>
            <td className={c(bs['text-center'], s.td, s.min, s.premium)}><b>{props.PricePremium}</b></td>
        </tr>
        {props.pricedProperties && props.pricedProperties.map((property: any, index: number) =>
            <PropertyTableRow
                small={true}
                property={property}
                even={false}
                key={index}
            />
        )}
        {props.PriceMassivbau ?
            <tr className={c(s.tr)}>
                <td className={c(s.td)}><small>Zusatzpreis für Massivbau-Haus:</small></td>
                <td className={c(bs['text-center'], s.td)}><small>{props.PriceMassivbau}</small></td>
                <td className={c(bs['text-center'], s.td)}><small>{props.PriceMassivbau}</small></td>
            </tr>
            : null}
        </tbody>
    </table>;
}

export default PropertyTable;
