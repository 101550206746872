import React, {useEffect, useState} from "react";
import Client from "../../Service/ApiService";
import Autocomplete from "react-autocomplete";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import s from "./CitySearch.module.scss"
import gs from "../../Theme/common.module.scss";

interface Props {
    onChange?(id: number): void,

    valid?: boolean
}

function CitySearch(props: Props) {
    const [location, setLocation] = useState('');

    const [id, setId] = useState<number>(0);
    const onChange = props.onChange ?? null;
    useEffect(() => {
        if (typeof onChange === "function") {
            onChange(id);
        }
    }, [id])

    const [locationSuggestions, setLocationSuggestions] = useState([]);
    useEffect(() => {
        if (location.length > 0) {
            Client.citySearch(location).then((result) => setLocationSuggestions(result.Results))
        } else {
            setLocationSuggestions([]);
        }
    }, [location])

    const onChangeHandler = (text: string) => {
        setLocation(text);
        setId(0);
    }

    return (
        <Autocomplete
            wrapperProps={{className: c(bs['col-12'])}}
            wrapperStyle={{}}
            getItemValue={(item) => item.ZIP + ' ' + item.City}
            items={locationSuggestions}
            renderItem={(item, isHighlighted) =>
                <div key={item.ID} className={c(s.item, isHighlighted ? s.highlighted : null)}>
                    {item.ZIP} {item.City}
                </div>
            }
            menuStyle={{
                zIndex: 9001,
                position: "absolute",
                maxHeight: "400px",
                overflow: "auto",
                top: "unset"
            }}
            renderInput={(props) =>
                <input {...props} type="text"
                       className={c(gs['customFormControl'], bs['form-control'], location.length ? (id > 0 ? bs['is-valid'] : bs['is-invalid']) : null)}
                       id="place"
                       placeholder="4614 Marchtrenk"/>
            }
            value={location}
            onChange={(e) => onChangeHandler(e.target.value)}
            onSelect={(val, item) => {
                setLocation(val);
                setId(item.ID);
            }}
        />
    );
}

export default CitySearch;
