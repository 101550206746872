export const checkName = (name: string): boolean => {
    return !!name.match(/^[^\d\s\t\n\r*+'#;,:!"§$%&/()=?°\\^]+ [^\d\s\t\n\r*+'#;,:!"§$%&/()=?°\\^]+(?: [^\d\s\t\n\r*+'#;,:!"§$%&/()=?°\\^]+)*$/);
}

export const checkPhone = (phone: string): boolean => {
    return !!phone.match(/\+?[0-9 ]{7,40}/);
}

export const checkMail = (mail: string): boolean => {
    return !!mail.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
}
