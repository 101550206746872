import React, {useEffect, useState} from "react";
import styles from "./Main.module.scss";
import {Route, RouteComponentProps, Switch, useHistory, withRouter} from "react-router-dom";
import Form from "../Form/Form";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import {SquareLoader} from "react-spinners";
import ApiService from "../../Service/ApiService";
import Submit from "../Submit/Submit";
import Result from "../Result/Result";
import Request from "../Request/Request";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import ViewOffer from "../ViewOffer/ViewOffer";

export interface Config {
    ID?: number,
    Title?: string,
    NeoFlowConstructionOptions?: any[],
    NeoFlowPersonOptions?: any[],
    NeoFlowRooms?: any[],
}

export interface Submission {
    ZipID?: number,
    Name?: string,
    Email?: string,
    Phone?: string,
    Area?: number,
    DSGVO?: boolean,
    marketing?: boolean,
    ConstructionOptionID?: number,
    PersonOptionID?: number,
    RoomOptions?: RoomOption[]
}

export interface RoomOption {
    ID?: number,
    Amount?: number
}

function Main(props: RouteComponentProps) {
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState<Config>({});
    const [submission, setSubmission] = useState<Submission>({});

    function updateSubmission(partialSubmission: Submission) {
        setSubmission(Object.assign(submission, partialSubmission))
    }

    const history = useHistory();

    function updateSubmissionAndSubmit(partialSubmission: Submission) {
        updateSubmission(partialSubmission);
        setLoading(true);
        ApiService.submit(submission)
            .then((json) => {
                history.push('/submit/' + json.Hash + '/success');
                setLoading(false);
            })
    }

    function goToResult(hash: string) {
        history.push('/result/' + hash);
    }

    useEffect(() => {
        if (typeof config.ID === 'undefined') {
            ApiService.config().then((r) => {
                setConfig(r);
                setLoading(false);
            });
        }
    })


    const [results, setResults] = useState<any>(null);
    const [hash, setHash] = useState<string | null>(null);
    useEffect(() => {
        setLoading(true);
        if (hash !== null && hash.length) {
            ApiService.results(hash).then(r => {
                setResults(r);
                console.log(r);
                setLoading(false);
            })
        }
    }, [hash])

    return (
        <ScrollTop>
            <div className={c(bs['my-5'], bs['text-center'])}>
                <SquareLoader loading={loading} color="#e30513" size={50}/>
            </div>
            {!loading &&
            <div className={c(styles.main, 'configurator-main', bs['mx-auto'], bs['container-fluid'])}>
                <Switch>
                    <Route exact={true} path={'/'}>
                        <Form config={config} onSubmit={updateSubmission}/>
                    </Route>
                    <Route exact={true} path={'/submit'}>
                        <Submit onSubmit={updateSubmissionAndSubmit}/>
                    </Route>
                    <Route exact={true} path={'/submit/:hash/success'} component={(props: any) =>
                        <Result hash={props.match.params.hash} onChangeHash={(hash) => setHash(hash)}
                                results={results}/>
                    }/>
                    <Route exact={true} path={'/result/:hash'} component={(props: any) =>
                        <Result hash={props.match.params.hash} onChangeHash={(hash) => setHash(hash)}
                                results={results}/>
                    }/>
                    <Route exact={true} path={'/request/:hash'} component={(props: any) =>
                        <Request hash={props.match.params.hash} onChangeHash={(hash) => setHash(hash)}
                                 results={results}/>
                    }/>
                </Switch>
            </div>
            }
        </ScrollTop>
    );
}

export default withRouter(props => <Main {...props}/>);
