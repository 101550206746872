interface ErrorResponse {
    message: string
}

export type ApiResponse<Type = any> = ErrorResponse & Array<Type> & Type;

const config = async (): Promise<ApiResponse> => {
    let url = process.env.REACT_APP_API_BASE_URL + 'getConfig';
    try {
        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });


        return await response.json();
    } catch (e) {
        return new Promise(() => {
            return {
                message: e.toString() ?? 'Unknown Error'
            }
        });
    }
}

const results = async (hash: string): Promise<ApiResponse> => {
    let url = process.env.REACT_APP_API_BASE_URL + 'results/' + hash;
    try {
        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });


        return await response.json();
    } catch (e) {
        return new Promise(() => {
            return {
                message: e.toString() ?? 'Unknown Error'
            }
        });
    }
}

const citySearch = async (q: string): Promise<ApiResponse> => {
    let url = process.env.REACT_APP_API_BASE_URL + 'citysearch';
    try {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({q: q})
        });


        return await response.json();
    } catch (e) {
        return new Promise(() => {
            return {
                message: e.toString() ?? 'Unknown Error'
            }
        });
    }
}

const submit = async (data: object): Promise<ApiResponse> => {
    let url = process.env.REACT_APP_API_BASE_URL + 'submit';
    try {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });


        return await response.json();
    } catch (e) {
        return new Promise(() => {
            return {
                message: e.toString() ?? 'Unknown Error'
            }
        });
    }
}

const request = async (data: object): Promise<ApiResponse> => {
    let url = process.env.REACT_APP_API_BASE_URL + 'submitCallMe';
    try {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });


        return await response.json();
    } catch (e) {
        return new Promise(() => {
            return {
                message: e.toString() ?? 'Unknown Error'
            }
        });
    }
}


const Client = {
    config,
    citySearch,
    submit,
    results,
    request
};

export default Client;
