import React, {useEffect, useState} from "react";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import ApiService from "../../Service/ApiService";
import gs from "../../Theme/common.module.scss";
import {checkName, checkPhone} from "../../Helper/RegexHelper";

interface Props {
    hash: string,
    results?: any,
}

function RequestForm(props: Props) {
    const [name, setName] = useState(props.results?.Name ?? '');
    const [nameValid, setNameValid] = useState<boolean>(true);

    const [phone, setPhone] = useState(props.results?.Phone ?? '');
    const [telValid, setTelValid] = useState<boolean>(true);
    const [message, setMessage] = useState('');

    const [error, setError] = useState<boolean>(false);

    const [sent, setSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dots, setDots] = useState<string>('');
    const [dotInterval, setDotInterval] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (dotInterval) {
            clearInterval(dotInterval);
        }
        if (loading) {
            setDots('');
            setDotInterval(setInterval(() => setDots(d => d.length > 4 ? '.' : d + '.'), 1000));
        }
    }, [loading])

    useEffect(() => {
        setNameValid(checkName(name));
    }, [name])

    useEffect(() => {
        setTelValid(checkPhone(phone));
    }, [phone])

    useEffect(() => {
        setError(false);
    }, [name, nameValid, phone, telValid]);

    const onSubmit = () => {
        if (name.length && nameValid && phone.length && telValid) {
            setError(false);
            setLoading(true);
            ApiService
                .request({
                    Hash: props.hash,
                    Name: name,
                    Phone: phone,
                    Message: message
                })
                .then(() => {
                    setSent(true);
                    window.location.replace('/konfigurator/abgeschlossen/');
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            setError(true);
        }
    }

    return <div id={'requestForm'}>
        <h2>Telefonische Beratung sichern</h2>
        <h3 className={c(bs['mb-5'])}>Sichern Sie sich Ihre persönliche Beratung</h3>
        {loading ? <div className={c(bs['text-center'], bs['my-5'])}>Ihre Anfrage wird gesendet{dots}</div> : <>
            {!sent && <>
                <div className={c(bs['mb-5'], bs['row'])}>
                    <label htmlFor="name" className={c(bs['col-12'], bs['col-form-label'])}>
                        Vor- und Nachname
                    </label>
                    <div className={c(bs['col-12'])}>
                        <input type="text"
                               className={c(bs['form-control'], gs['customFormControl'], name.length ? (nameValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                               id="name"
                               placeholder="Michaela Mustermann"
                               autoComplete="name"
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className={c(bs['mb-5'], bs['row'])}>
                    <label htmlFor="phone" className={c(bs['col-12'], bs['col-form-label'])}>
                        Telefon
                    </label>
                    <div className={c(bs['col-12'])}>
                        <input type="text"
                               className={c(bs['form-control'], gs['customFormControl'], phone.length ? (telValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                               id="phone" placeholder="+43 7232 123456"
                               autoComplete="tel"
                               value={phone}
                               onChange={(e) => setPhone(e.target.value.match(/[+ 0-9]*/)?.join('') ?? '')}
                        />
                    </div>
                </div>
                <div className={c(bs['mb-5'], bs['row'])}>
                    <label htmlFor="message" className={c(bs['col-12'], bs['col-form-label'])}>
                        Nachricht
                    </label>
                    <div className={c(bs['col-12'])}>
                <textarea
                    className={c(bs['form-control'], gs['customFormControl'])}
                    id="message"
                    placeholder="Ich möchte..."
                    autoComplete="off"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                    </div>
                </div>
                <div className={c(bs['mb-5'])}>
                    <p className={c(bs['text-danger'], error ? null : bs['d-none'])}>
                        Bitte füllen Sie alle erforderlichen Felder aus!
                    </p>
                    <button onClick={() => onSubmit()} className={gs.bigRedButton}>Rufen Sie mich an</button>
                </div>
            </>}
            {sent &&
            <p className={c(bs['mb-5'])}>Vielen Dank für die Anfrage! Wir werden uns in Kürze bei Ihnen melden.</p>}
        </>}
    </div>
}

export default RequestForm;
