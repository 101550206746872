import React, {useState} from "react";
import c from "classnames";
import r from "../../Containers/Result/Result.module.scss";
import bs from "../../Theme/bootstrap.module.css";
import s from "./PropertyTable.module.scss";

interface Props {
    property: any,
    even: boolean,
    small?: boolean
}

function PropertyTableRow(props: Props) {
    const [open, setOpen] = useState<boolean>(false);

    const circlePng = process.env.REACT_APP_HOST_BASE_URL + "public/resources/themes/quickfix-clean/img/neoflow/circle.png";
    const checkPng = process.env.REACT_APP_HOST_BASE_URL + "public/resources/themes/quickfix-clean/img/neoflow/check.png";

    const hasContent = props.property.Content && props.property.Content.length;
    const handleClick = () => {
        if (hasContent) {
            setOpen(!open)
        }
    }

    return <>
        <tr className={c(props.even ? s.bgLight : s.bgNone, s.tr)}>
            <td className={c(s.titleCell, s.td, hasContent ? s.dropdownTitleCell : null)}
                onClick={() => handleClick()}
                role={hasContent ? "button" : "none"}
            >
                {props.small ? <small>{props.property.Title}</small> : props.property.Title}
                {hasContent && <span className={c(s.plus, open ? s.plusOpen : null)}>+</span>}
            </td>
            <td className={c(bs['text-center'], s.td, s.vAlign, s.min, s.comfort)}>
                {props.property.Comfort ?
                    (props.property.Price ?
                            (props.small ? <small>{props.property.Price}</small> : props.property.Price) :
                            (props.property.ShowAsCircle ?
                                    <img src={circlePng} className={r.checkCircle} alt="Optional"/> :
                                    <img src={checkPng} className={r.checkCircle} alt="Inklusive"/>
                            )
                    )
                    : null
                }

            </td>
            <td className={c(bs['text-center'], s.td, s.vAlign, s.min, s.premium)}>
                {props.property.Premium ?
                    (props.property.Price ?
                            (props.small ? <small>{props.property.Price}</small> : props.property.Price) :
                            (props.property.ShowAsCircle ?
                                <img src={circlePng} className={r.checkCircle} alt="Optional"/> :
                                <img src={checkPng} className={r.checkCircle} alt="Inklusive"/>)
                    )
                    : null
                }
            </td>
        </tr>
        {hasContent &&
        <tr className={c(s.closedRow, s.tr, open ? s.openRow : null)}>
            <td colSpan={3}
                className={c(s.td, s.infoRow, s.moreInfo, open ? s.visible : null)}>{props.property.Content}</td>
        </tr>
        }
    </>;
}

export default PropertyTableRow;
