import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {wait} from "@testing-library/react";

// @ts-ignore
function ScrollTop({history, children}) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            const element = document.getElementById('neoflow-configurator');
            if (element) {
                setTimeout(function (){
                    element.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})
                },150)
            }
        });
        return () => {
            unlisten();
        }
    }, []);

    return <>{children}</>;
}

// @ts-ignore
export default withRouter(ScrollTop);
