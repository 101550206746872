import React, {useEffect, useState} from "react";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import s from "./AmountCard.module.scss";

interface Props {
    imageSrc?: string,
    number?: number,
    title: string,
    id: number,

    onChange?(roomId: number, number: number): void
}

function AmountCard(props: Props) {
    const [number, setNumber] = useState(props.number ?? 0);
    const onChange = props.onChange;
    const id = props.id;
    useEffect(() => {
        if (typeof onChange === "function") {
            onChange(id, number)
        }
    }, [number]);

    return (
        <div className={c(bs['col-xl-3'], bs['col-lg-4'], bs['col-sm-6'], bs['col-6'])}>
            <div className={s.wrapper}>
                {props.imageSrc ?
                    <img src={props.imageSrc} alt={props.title} className={c(s.image, bs['img-fluid'])}/> : null}
                <span className={s.title}>{props.title}</span>
                <div className={c(s.toolbar)}>
                    <button onClick={() => setNumber(number - 1)} disabled={number < 1}>-</button>
                    <span>{number}</span>
                    <button onClick={() => setNumber(number + 1)}>+</button>
                </div>
            </div>
        </div>
    );
}

export default AmountCard;
